import React, { useCallback, useEffect, useRef, useState } from "react";
import { KeyBoard } from "../Constant";

export default function MobileSwiper({ children, onSwipe, className }) {
  const wrapperRef = useRef();
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const handleTouchStart = useCallback((e) => {
    if (!wrapperRef.current?.contains(e.target)) {
      return;
    }

    e.preventDefault();

    setStartX(e.touches[0].clientX);
    setStartY(e.touches[0].clientY);
  }, []);

  const handleTouchEnd = useCallback(
    (e) => {
      if (!wrapperRef.current?.contains(e.target)) {
        return;
      }

      e.preventDefault();

      const endX = e.changedTouches[0].clientX;
      const endY = e.changedTouches[0].clientY;
      const deltaX = endX - startX;
      const deltaY = endY - startY;

      // callback
      // set 3 as breakpoint
      if (Math.abs(deltaX) > 3 || Math.abs(deltaY) > 3) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (deltaX > 0) {
            onSwipe(KeyBoard.Right);
          } else {
            onSwipe(KeyBoard.Left);
          }
        } else if (Math.abs(deltaX) < Math.abs(deltaY)) {
          if (deltaY > 0) {
            onSwipe(KeyBoard.Down);
          } else {
            onSwipe(KeyBoard.Up);
          }
        }
      }

      setStartX(0);
      setStartY(0);
    },
    [startX, startY, onSwipe]
  );

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart, { passive: false });
    window.addEventListener("touchend", handleTouchEnd, { passive: false });

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchEnd]);

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
}
