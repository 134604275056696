import { useCallback, useEffect, useState } from "react";
import { DownArrowIcon } from "../../components/DownArrowIcon";
import { LeftArrowIcon } from "../../components/LeftArrowIcon";
import { RightArrowIcon } from "../../components/RightArrowIcon";
import { UpArrowIcon } from "../../components/UpArrowIcon";
import { KeyBoard } from "../../Constant";
import { initEnemies } from "./enemies";
import { gameCycle, renderCycle, timeouts } from "./init";
import { drawMap } from "./map";
import { addKeys, onKeyDown, onKeyUp, player } from "./player";
import { initScreen } from "./renderer";
import { initSprites } from "./sprites";
import "./styles.css";

export default function Labyrinth() {
  const [showMap, setShowMap] = useState(true);
  const [status, setStatus] = useState("");
  useEffect(() => {
    addKeys();
    initScreen();
    initSprites();
    initEnemies();
    drawMap();
    gameCycle();
    renderCycle();
    return () => {
      for (var i = 0; i < timeouts.length; i++) {
        clearTimeout(timeouts[i]);
      }
    };
  }, []);

  const handleKeyUp = useCallback((step) => {
    if (player.x >= 30 && player.y >= 30) setStatus("won");
    switch (step) {
      case KeyBoard.Down:
        onKeyUp(40);
        break;
      case KeyBoard.Up:
        onKeyUp(38);
        break;
      case KeyBoard.Left:
        onKeyUp(37);
        break;
      case KeyBoard.Right:
        onKeyUp(39);
        break;
      default:
        break;
    }
  }, []);

  const handleKeyDown = useCallback((step) => {
    if (player.x >= 30 && player.y >= 30) setStatus("won");
    switch (step) {
      case KeyBoard.Down:
        onKeyDown(40);
        break;
      case KeyBoard.Up:
        onKeyDown(38);
        break;
      case KeyBoard.Left:
        onKeyDown(37);
        break;
      case KeyBoard.Right:
        onKeyDown(39);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div id="screen">
        <div id="floor"></div>
        <div id="ceiling"></div>
        <div id="map" className={`cursor-pointer opacity-10 opacity-${showMap ? 100 : 10}`} onClick={() => setShowMap(!showMap)}>
          <canvas id="minimap"></canvas>
          <canvas id="objects"></canvas>
        </div>
      </div>
      <div id="keypad" className="md:hidden mt-4 fixed bottom-4 left-0 right-0 flex justify-center z-[999]">
        <section>
          <button aria-label="up arrow" onTouchStart={() => handleKeyDown("Up")} onTouchEnd={() => handleKeyUp("Up")}>
            <UpArrowIcon className="w-4" />
          </button>
          <button aria-label="left arrow" onTouchStart={() => handleKeyDown("Left")} onTouchEnd={() => handleKeyUp("Left")}>
            <LeftArrowIcon className="w-4" />
          </button>
          <button aria-label="down arrow" onTouchStart={() => handleKeyDown("Down")} onTouchEnd={() => handleKeyUp("Down")}>
            <DownArrowIcon className="w-4" />
          </button>
          <button aria-label="right arrow" onTouchStart={() => handleKeyDown("Right")} onTouchEnd={() => handleKeyUp("Right")}>
            <RightArrowIcon className="w-4" />
          </button>
        </section>
      </div>
      {status === "won" && (
        <div className="info fixed w-full left-0 right-0 text-center top-[200px] z-[9999]">
          <p>You won!</p>
        </div>
      )}
    </>
  );
}
