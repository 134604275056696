import React from "react";
import "./keypad.css";
import Image from "../../../../static/x.svg";

const Keypad = ({ handleClick }) => {
  let keys = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    .map((num) => {
      return (
        <div onClick={() => handleClick(num)} className="key w-[55px] h-[42px] m-2" key={num}>
          {num}
        </div>
      );
    })
    .concat([<div onClick={() => handleClick(0)} className="key w-[55px] h-[42px] m-2 bg-no-repeat bg-center bg-size bg-[size:15px]" key={"X"} style={{ backgroundImage: "url(" + Image + ")" }}></div>]);
  return <div className="text-black flex mt-3 mb-3 w-[360px] flex-wrap">{keys}</div>;
};

export default Keypad;
