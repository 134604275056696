export const SudokuGrids = [
  {
    "grid": "061020530000700002580104600000006800023590001400002007607000390200049010059803040",
    "solvedGrid": "761928534394765182582134679975416823823597461416382957647251398238649715159873246"
  },
  {
    "grid": "302000070950080004000097530000000019001034007028600400070800000103070046549200001",
    "solvedGrid": "312456978957382164864197532435728619691534827728619453276841395183975246549263781"
  },
  {
    "grid": "008700000510600003003000495065009070280000040009028106054010900120040508007360010",
    "solvedGrid": "948753621512694783673281495465139872281576349739428156354812967126947538897365214"
  },
  {
    "grid": "604007080100059200020300004000705023000048071906010500500032009040000000831060700",
    "solvedGrid": "694127385183459267725386194418795623352648971976213548567832419249571836831964752"
  },
  {
    "grid": "036970500008640700900003001150029070070000802000000019420006100090038045603410000",
    "solvedGrid": "236971584518642793947583261154829376379164852862357419425796138791238645683415927"
  },
  {
    "grid": "090304100000580307250000980038402006500100042001650890070003000000210000006870201",
    "solvedGrid": "897324165614589327253761984738492516569138742421657893172943658385216479946875231"
  },
  {
    "grid": "068009003300500020527604800012000046400208900009017000050302008080070594701000060",
    "solvedGrid": "168729453394581627527634819812953746475268931639417285956342178283176594741895362"
  },
  {
    "grid": "200538000700402006830070005091000000000049108500600470060210304308060010004700900",
    "solvedGrid": "246538791715492836839176245491857623672349158583621479967215384328964517154783962"
  },
  {
    "grid": "140200070700080000005000643400017080800054029097006000510030004020590800003000210",
    "solvedGrid": "149263578736485192285179643452917386861354729397826451518732964624591837973648215"
  },
  {
    "grid": "503010009010400600407092008600000703000809401002057800904003500800070060700125000",
    "solvedGrid": "583716249219438675467592138698241753375869421142357896924683517851974362736125984"
  },
  {
    "grid": "096070040080100093205084060000200070000340005613000028029007000000050007450819000",
    "solvedGrid": "196573842784126593235984761548261379972348615613795428829637154361452987457819236"
  },
  {
    "grid": "409070060000506700030021050704053900002080010090004230128039400000000620506700008",
    "solvedGrid": "459378162281596743637421859714253986362987514895164237128639475973845621546712398"
  },
  {
    "grid": "080200100003600050940007000006000008031508700070109504708001030250000000000740206",
    "solvedGrid": "687253149123694857945817623596472318431568792872139564768921435254386971319745286"
  },
  {
    "grid": "700002518010300070500800003160040090000000087004935200080290056600004700030601002",
    "solvedGrid": "793462518218359674546817923162748395359126487874935261487293156621584739935671842"
  },
  {
    "grid": "001084602200500090600037410098002304000000007036800100402150006003400050000309700",
    "solvedGrid": "351984672247561893689237415598712364124693587736845129472158936913476258865329741"
  },
  {
    "grid": "000306297700501000460090010105084030090007002048000500007605804020809060003000100",
    "solvedGrid": "851346297739521648462798315175284936396157482248963571917635824524819763683472159"
  },
  {
    "grid": "000060210015000640094200070008704000700000390900103008007050982100600000350008001",
    "solvedGrid": "873469215215837649694215873538794126721586394946123758467351982189642537352978461"
  },
  {
    "grid": "300001290090530000185070004070020908000657000406900020203140700810000350000086400",
    "solvedGrid": "347861295692534871185279634571423968928657143436918527263145789814792356759386412"
  },
  {
    "grid": "007600051018590700000008030049100380760050040050230900300000290002005006486007000",
    "solvedGrid": "937642851618593724524718639249176385763859142851234967375461298192385476486927513"
  },
  {
    "grid": "007010804006502300900843700028000006300021400000090050070004090405000070091780200",
    "solvedGrid": "537916824846572319912843765128457936359621487764398152273164598485239671691785243"
  },
  {
    "grid": "000000517000306000592008000100005846040730900209600071901042005030900600004000008",
    "solvedGrid": "386429517417356289592178463173295846648731952259684371961842735835917624724563198"
  },
  {
    "grid": "002004050015068390300007000900000060000001204806720500000309420060810005200500870",
    "solvedGrid": "672934158415268397389157642921485763537691284846723519158379426764812935293546871"
  },
  {
    "grid": "405002000060900074000610030010080000807003900293000840050008102300206090004700005",
    "solvedGrid": "435872619168935274972614538516489723847123956293567841759348162381256497624791385"
  },
  {
    "grid": "210700980500001006670340200020000090840200501006080004003065070000800409400009038",
    "solvedGrid": "214756983538921746679348215325614897847293561196587324983465172761832459452179638"
  },
  {
    "grid": "000690030509801020860000400070003060600025000090000540701480002000300000038019057",
    "solvedGrid": "217694835549831726863572419175943268684125973392768541751486392926357184438219657"
  },
  {
    "grid": "000049000080030040406700908300952070007001503009000680000007001812500300035060020",
    "solvedGrid": "573849216981236745426715938368952174247681593159473682694327851812594367735168429"
  },
  {
    "grid": "790000200035104080100037000056000030003061052000009800002008965080040001517006040",
    "solvedGrid": "794685213235194687168237594856472139973861452421359876342718965689543721517926348"
  },
  {
    "grid": "509008060760309000083012050010080637000500904040006080004070123002000005008091400",
    "solvedGrid": "529748361761359248483612759215984637836527914947136582694875123172463895358291476"
  },
  {
    "grid": "007008009008094027000500604506000298009320400010000060803105000140002000090087305",
    "solvedGrid": "467218539358694127921573684536741298789326451214859763873165942145932876692487315"
  },
  {
    "grid": "000402009000001050001070083605008020204360700030700090800047600540009070006580001",
    "solvedGrid": "387452169962831457451976283675198324294365718138724596819247635543619872726583941"
  },
  {
    "grid": "005000140190600000620730000040900005980064003507280010300009681000000000470108029",
    "solvedGrid": "735892146198645732624731598243917865981564273567283914352479681819326457476158329"
  },
  {
    "grid": "019060080200904000780100560005001308100530097000072400020800700490300010060040035",
    "solvedGrid": "319765284256984173784123569675491328142538697938672451523819746497356812861247935"
  },
  {
    "grid": "406000082090304000005009003070090000003078060182600005040010506000036078800420019",
    "solvedGrid": "436751982298364157715289643674592831953178264182643795349817526521936478867425319"
  },
  {
    "grid": "050603100300000085070040200001026408030008002700104090820001609067005001900300070",
    "solvedGrid": "259683147346217985178549263591726438634958712782134596823471659467895321915362874"
  },
  {
    "grid": "290000100060520030704008059000004008058610000010035690100003040009700820002090007",
    "solvedGrid": "295347186861529734734168259623974518958612473417835692176283945349756821582491367"
  },
  {
    "grid": "000300510504008206060900073012700050000001380070096400605037000003050002800004900",
    "solvedGrid": "789362514534178296261945873412783659956421387378596421695237148143859762827614935"
  },
  {
    "grid": "290004800001380425000510709080070506007600000940000008509020007003150000060803001",
    "solvedGrid": "295764813671389425438512769382971546157648932946235178519426387823157694764893251"
  },
  {
    "grid": "908000020000501000037049605500620309004007000060900108005010002002005034300860010",
    "solvedGrid": "958376421426581793137249685571628349894137256263954178785413962612795834349862517"
  },
  {
    "grid": "608000000000002040005908003230400900106000075000700002700060210901030508560084090",
    "solvedGrid": "628341759379652841415978623237415986196823475854796132783569214941237568562184397"
  },
  {
    "grid": "000900050060070083530008400084300069000407100250090300900261700170080092605000004",
    "solvedGrid": "817943256462175983539628471784312569396457128251896347948261735173584692625739814"
  },
  {
    "grid": "020300096003070041000009000100285000806040000500000703000657008305000604079008210",
    "solvedGrid": "728314596963572841451869327137285469896743152542196783214657938385921674679438215"
  },
  {
    "grid": "800073000090501400302600008010006370207410590569000000000000004184020050600009130",
    "solvedGrid": "841273965796581423352694718418956372237418596569732841973165284184327659625849137"
  },
  {
    "grid": "000350107500642030960180000804500793300000000007200045010000580002400001000860409",
    "solvedGrid": "248359167571642938963187254824516793356974812197238645419723586682495371735861429"
  },
  {
    "grid": "009000720030540018601007000916800000002604005400090000005071390720006004000305806",
    "solvedGrid": "549168723237549618681237549916852437372614985458793261865471392723986154194325876"
  },
  {
    "grid": "490300680500000000017008904001000063030004208060970100070100039823007010000400802",
    "solvedGrid": "492351687586749321317268954941825763735614298268973145674182539823597416159436872"
  },
  {
    "grid": "000941800045800700903000006002106040069000300304700025070008000236400009000605107",
    "solvedGrid": "627941853145863792983572416752136948869254371314789625571398264236417589498625137"
  },
  {
    "grid": "008700100070064500196080002209053070480001006000620030000019020007000400051007390",
    "solvedGrid": "548792163372164589196385742269453871483971256715628934834519627927836415651247398"
  },
  {
    "grid": "002058403400700000019006800000130908051420070904007100208000600307080095060200000",
    "solvedGrid": "672958413483712569519346827726135948851429376934867152298574631347681295165293784"
  },
  {
    "grid": "090014730000000008062007050000038600050700400403005020024081009038002001507900062",
    "solvedGrid": "895214736741356298362897154279438615156729483483165927624581379938672541517943862"
  },
  {
    "grid": "004759000861000007000000342200600500000020018790103004006201030580307600002008009",
    "solvedGrid": "324759186861432957957816342218674593643925718795183264476291835589347621132568479"
  },
  {
    "grid": "090082500803050006000307910581640300070000400002003090000091052006570104040000700",
    "solvedGrid": "697182543813954276254367918581649327379825461462713895738491652926578134145236789"
  },
  {
    "grid": "050009067004158900230400001100080394006012080000304020000000003090500670628000500",
    "solvedGrid": "851239467764158932239467851172685394346912785985374126517896243493521678628743519"
  },
  {
    "grid": "090600708010920000380070029200010067500200300870090004050000190023781500006030000",
    "solvedGrid": "492653718617928435385174629234815967569247381871396254758462193923781546146539872"
  },
  {
    "grid": "000870000425000970890020610040680005102000000070003090619730500200068000030100409",
    "solvedGrid": "361879254425316978897425613943681725182597346576243891619734582254968137738152469"
  },
  {
    "grid": "080020390000500462900740005060007000000480016042030500001900000008051640003062750",
    "solvedGrid": "584126397317598462926743185865217934739485216142639578651974823278351649493862751"
  },
  {
    "grid": "940608075800200091000050300070000000084901000030085602650103000007020050021004780",
    "solvedGrid": "943618275865237491712459368576342819284961537139785642658173924497826153321594786"
  },
  {
    "grid": "007000009960302000812060407070250100008004200056800074000040586000509000300007012",
    "solvedGrid": "537418629964372851812965437473256198198734265256891374729143586681529743345687912"
  },
  {
    "grid": "409010057600004300800607020000100800040200005930080762000391000105000030006040980",
    "solvedGrid": "429813657657924318813657429562179843748236195931485762284391576195768234376542981"
  },
  {
    "grid": "007902000280006007604000305520030600100600054006078001030405700005010090419200003",
    "solvedGrid": "357942168281356947694781325528134679173629854946578231832495716765813492419267583"
  },
  {
    "grid": "006108500090040070000009238052000000040030829000010306009500000083067140027084060",
    "solvedGrid": "236178594895243671714659238352896417641735829978412356469521783583967142127384965"
  },
  {
    "grid": "001030050600004800900068020307000460000002907408050002040100000002679001105003798",
    "solvedGrid": "281937654653214879974568123327891465516342987498756312749185236832679541165423798"
  },
  {
    "grid": "030640025080103009075802100004060902008000513010900700060009000907000040003028001",
    "solvedGrid": "139647825286153479475892136754361982698274513312985764861439257927516348543728691"
  },
  {
    "grid": "010004508500706091038000270002003000007000819900601000003900000070205046206100730",
    "solvedGrid": "719324568524786391638519274142893657367452819985671423453967182871235946296148735"
  },
  {
    "grid": "009820701006040002030700050005108040081090000620030090700300000910206870000980415",
    "solvedGrid": "549823761176549382832761954395178246481692537627435198758314629914256873263987415"
  },
  {
    "grid": "045010800006257000370600000000970301402000080001003406900002070800406095004005100",
    "solvedGrid": "245319867186257943379648512568974321432561789791823456953182674817436295624795138"
  },
  {
    "grid": "890043005000700021000206403280060700001070500060819042300001080729000000600050004",
    "solvedGrid": "892143675436795821175286493283564719941372568567819342354621987729438156618957234"
  },
  {
    "grid": "100080002057023408002600300000039200000500070900804506509000060030700001804010905",
    "solvedGrid": "193487652657923418482651397765139284348562179921874536519248763236795841874316925"
  },
  {
    "grid": "056093000701205006200700040060001902000580030840060700090004001305109400000020300",
    "solvedGrid": "456893127731245896289716543567431982912587634843962715698354271325179468174628359"
  },
  {
    "grid": "060230108100004590000000706000985030600001009050760402900007064732500080410000300",
    "solvedGrid": "567239148128674593394158726241985637673421859859763412985317264732546981416892375"
  },
  {
    "grid": "190004000003600980008720010004001670500483090200000000900050847050042301060300050",
    "solvedGrid": "195834726723615984648729513384291675576483192219567438932156847857942361461378259"
  },
  {
    "grid": "903876000000490207008500000007051060600000905042008013050000132010709800804020000",
    "solvedGrid": "923876541165493287478512396397251468681347925542968713759684132216739854834125679"
  },
  {
    "grid": "008090106600030280030004009001040050080706000470508000304001060020070905500900048",
    "solvedGrid": "748295136659137284132684579261349857985716423473528691394851762826473915517962348"
  },
  {
    "grid": "200360041009000000050090820000602034401508706007010000086900000030041500004080079",
    "solvedGrid": "278365941149827653653194827895672134421538796367419285786953412932741568514286379"
  },
  {
    "grid": "960048703003000020700050800208004037071603004000000900050100062630925040080030009",
    "solvedGrid": "965248713813769425724351896298514637571693284346872951459187362637925148182436579"
  },
  {
    "grid": "000040608004891050300002001042007900050089070900500006090070082205030000610005407",
    "solvedGrid": "521743698764891253389652741842367915156289374937514826493176582275438169618925437"
  },
  {
    "grid": "900057006182300009050001020800503061009008200370000040005090304007100080000742100",
    "solvedGrid": "943257816182364759756981423824573961569418237371629548215896374497135682638742195"
  },
  {
    "grid": "165000080000059760007003401000006040000090207043805000054027600070064908002310000",
    "solvedGrid": "165742389438159762927683451719236845586491237243875196854927613371564928692318574"
  },
  {
    "grid": "008600500504039000107040020050900340800700900706014080000120000009063802010008756",
    "solvedGrid": "928671534564239178137845629251986347843752961796314285685127493479563812312498756"
  },
  {
    "grid": "040370500007008203800190060058006007210000300000040980730610005002903001005820400",
    "solvedGrid": "641372598597468213823195764958236147214789356376541982739614825482953671165827439"
  },
  {
    "grid": "002500700380200009150487000600800200074030000000000631700010003040002586006905020",
    "solvedGrid": "462593718387261459159487362613859274274136895598724631725618943941372586836945127"
  },
  {
    "grid": "500007000003060502104300089800009014030702600002010305000506001906040000070108430",
    "solvedGrid": "529487163783961542164325789857639214431752698692814375348576921916243857275198436"
  },
  {
    "grid": "000903002090057360340108000000600470007000091210700586059020100800000030604580700",
    "solvedGrid": "576943812198257364342168957985612473467835291213794586759326148821479635634581729"
  },
  {
    "grid": "020300600870024309009070020006082001090100054300500908400008003000065000051790040",
    "solvedGrid": "124359687875624319639871425546982731798136254312547968467218593983465172251793846"
  },
  {
    "grid": "405607200600900080090002043000053870708400320010000006080070095009006000143200700",
    "solvedGrid": "435687219621934587897512643962153874758469321314728956286371495579846132143295768"
  },
  {
    "grid": "640000000085010300013690502000027000039000150070050804001079080008304001490500260",
    "solvedGrid": "642735918985412376713698542854127639239846157176953824361279485528364791497581263"
  },
  {
    "grid": "200070185009000037000180060080005006007400300051760209020603500730840090004090000",
    "solvedGrid": "246379185819256437573184962482935716697421358351768249928613574735842691164597823"
  },
  {
    "grid": "030058000908000720100670000000400800073290001006000032052030140710040080000700906",
    "solvedGrid": "237958614968314725145672398521463879873295461496187532652839147719546283384721956"
  },
  {
    "grid": "060051000700203000000096280190087500504000300000000069250000600081030790009415003",
    "solvedGrid": "962851437718243956345796281196387542524169378837524169253978614481632795679415823"
  },
  {
    "grid": "500463008432090000000705100300206400009050060027840001050080036096000002000017590",
    "solvedGrid": "571463928432198675968725143315276489849351267627849351154982736796534812283617594"
  },
  {
    "grid": "001007650000003082200040000000015730100400096089000020300800069097000105604501003",
    "solvedGrid": "931287654746153982258649317462915738173428596589736421315874269897362145624591873"
  },
  {
    "grid": "900010600005600740000008103054090206860702500100000030086203050070080000309056004",
    "solvedGrid": "938417625215639748647528193754391286863742519192865437486273951571984362329156874"
  },
  {
    "grid": "017000026008012507000000908400060080529700040800005109040053002000906300905800700",
    "solvedGrid": "317598426698412537254637918471369285529781643863245179746153892182976354935824761"
  },
  {
    "grid": "600710803002509070007000500700300010930800046040001082800025090005090004004130600",
    "solvedGrid": "659714823382569471417283569728346915931852746546971382863425197175698234294137658"
  },
  {
    "grid": "130000002000970803002040600070100200561028034094005080000050060908060701300000049",
    "solvedGrid": "139586472645972813782341695873194256561728934294635187417259368958463721326817549"
  },
  {
    "grid": "009620005002800319471000000027008090100050630800409500000035407940070020063200000",
    "solvedGrid": "389621745652847319471593286527368194194752638836419572218935467945176823763284951"
  },
  {
    "grid": "190008607026003900030060450059040380870300000000016000480250070000100020200007809",
    "solvedGrid": "194528637526473918738961452659742381871395264342816795483259176967184523215637849"
  },
  {
    "grid": "030800600107002000800036015020953840000020007943080100009010408600000070350700090",
    "solvedGrid": "435871629167592384892436715721953846586124937943687152279315468618249573354768291"
  },
  {
    "grid": "061000003005090040700000821570843000902017400000000006080360050057400100400020908",
    "solvedGrid": "861274593325198647794536821576843219932617485148952376289361754657489132413725968"
  },
  {
    "grid": "050000403002007500009000086108905240000001009700842010003200600504039100600700002",
    "solvedGrid": "857196423362487591419523786138965247245371869796842315973218654524639178681754932"
  },
  {
    "grid": "030080001090007584400690700002056309010000067049700000081264000003008010700003005",
    "solvedGrid": "237485691196327584458691732872156349315849267649732158581264973923578416764913825"
  },
  {
    "grid": "502700900009003001060820700000430005085002004017060200040005086020314000700900300",
    "solvedGrid": "532741968879653421164829753296437815385192674417568239943275186628314597751986342"
  },
  {
    "grid": "006128070040000500009005063080009027007830400050042010000006040370000098900507102",
    "solvedGrid": "536128974742963581819475263481659327297831456653742819128396745375214698964587132"
  },
  {
    "grid": "001000506700908000200060300050040008870001243930080010400609000080312057010070600",
    "solvedGrid": "391427586765938124248165379152743968876591243934286715427659831689312457513874692"
  },
  {
    "grid": "061302050700000032200014960018000003000057040905408020034800006100560790000200000",
    "solvedGrid": "861392457749685132253714968418926573326157849975438621534879216182563794697241385"
  },
  {
    "grid": "106300000000041800200890507018500060700080450560700002000002970439000000007005143",
    "solvedGrid": "186357294975241836243896517318524769792683451564719382651432978439178625827965143"
  },
  {
    "grid": "000000804008071600000200039230709500500130090709080260340006020851003700060400001",
    "solvedGrid": "123695874498371652675248139234769518586132497719584263347816925851923746962457381"
  },
  {
    "grid": "020900051004300090300056008000000000002090486607083920100002060005007200048100007",
    "solvedGrid": "826974351754318692391256748489625173532791486617483925173542869965837214248169537"
  },
  {
    "grid": "207000003010090607060405001040067310000910020080203400008031070005600000009008052",
    "solvedGrid": "297186543514392687863475291942867315356914728781253469428531976175629834639748152"
  },
  {
    "grid": "001090704080300520627508000450000098000010360016900002740080600000062030300700040",
    "solvedGrid": "531296784984371526627548913453627198279814365816935472742183659195462837368759241"
  },
  {
    "grid": "700083100349000060000602000030850947800000510170240080050030709000000030260970450",
    "solvedGrid": "726483195349715268581692374632851947894367512175249683458136729917524836263978451"
  },
  {
    "grid": "010004090002500036070010425000689000546320001930000700007092050084700600000801000",
    "solvedGrid": "615234897492578136873916425721689543546327981938145762167492358284753619359861274"
  },
  {
    "grid": "003004500902000086570008290000693004040007002605001008037052000409010007006400310",
    "solvedGrid": "863924571912735486574168293728693154341587962695241738137852649459316827286479315"
  },
  {
    "grid": "000000087850960001941027006000080060004500302102000005760300090230604008000009050",
    "solvedGrid": "326145987857963421941827536573482169684591372192736845765318294239654718418279653"
  },
  {
    "grid": "960000704000800000031054009100020480002079000500400026620008300005000040809032601",
    "solvedGrid": "968213754754896132231754869196325487482679513573481926627148395315967248849532671"
  },
  {
    "grid": "007000400540800903320005680003600200002483005060501070805002004000710090900000010",
    "solvedGrid": "687239451541876923329145687153697248792483165468521379815962734234718596976354812"
  },
  {
    "grid": "000700002320096008079004005000072000008400903090600041200030160043001080680507000",
    "solvedGrid": "416758392325196478879324615134972856768415923592683741257839164943261587681547239"
  },
  {
    "grid": "094000002008570309710002080036201900020095406400000000640380000070006200501009700",
    "solvedGrid": "394618572268574319715932684836241957127895436459763821642387195973156248581429763"
  },
  {
    "grid": "000403000060092087103008050020905400400800000708060100005200000947030062001700590",
    "solvedGrid": "872453916564192387193678254326915478419827635758364129635289741947531862281746593"
  },
  {
    "grid": "507023001060070020400810900900630040030005109082004060100008000350900708070300005",
    "solvedGrid": "597423681861579324423816957915637842634285179782194563149758236356942718278361495"
  },
  {
    "grid": "002001080060952100530067004059100007100006008000070020400509000028000036310008709",
    "solvedGrid": "792341685864952173531867294259183467147296358683475921476539812928714536315628749"
  },
  {
    "grid": "507000300100060070030200095400908510000076000309100407008001046640080100050009203",
    "solvedGrid": "567894321192563874834217695476938512215476938389125467928351746643782159751649283"
  },
  {
    "grid": "064009350000008096530400002073000000400600280008512700905700040000240071100036008",
    "solvedGrid": "864129357712358496539467812273894165451673289698512734925781643386245971147936528"
  },
  {
    "grid": "008703509900050000207006000400600831000507406300040200060090084000108060700002310",
    "solvedGrid": "648713529913254678257986143475629831821537496396841257162395784534178962789462315"
  },
  {
    "grid": "010004002003090570670080030809650001031200060740031090000900428052700000000000006",
    "solvedGrid": "915374682283196574674582139829657341531249867746831295367915428452768913198423756"
  },
  {
    "grid": "610730080000050400040006321021048006400900005003070040700209008065000200009310070",
    "solvedGrid": "612734589398152467547896321921548736476923815853671942734269158165487293289315674"
  },
  {
    "grid": "038000000002190005010470600000006754850000000600030201206005009079800030540720100",
    "solvedGrid": "438652917762193845915478623321986754857241396694537281286315479179864532543729168"
  },
  {
    "grid": "803240700014800900900070806100004302406520100008001000007002095502300600600080007",
    "solvedGrid": "863249751714856923925173846159764382436528179278931564387612495542397618691485237"
  },
  {
    "grid": "604708000207604910030050000003006502400070090019003870800009604000001000900080025",
    "solvedGrid": "694718253257634918138952467783196542462875391519243876871529634325461789946387125"
  },
  {
    "grid": "000407000090053640780092100150280700400006000036900002340100506600008270020000014",
    "solvedGrid": "563417928291853647784692135159284763472536891836971452347129586615348279928765314"
  },
];
