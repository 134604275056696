import React, { useCallback, useContext, useEffect, useRef } from "react";
// import { Tile as TileModel } from "../models/tile";
import styles from "../../styles/board.module.css";
import Tile from "./Tile";
import { GameContext } from "../../context/game-context";
import MobileSwiper from "../../components/MobileSwiper";
import { KeyBoard } from "../../Constant";

export default function Board() {
  const { getTiles, moveTiles, startGame, status } = useContext(GameContext);
  const initialized = useRef(false);

  const handleKeyDown = useCallback(
    (e) => {
      // disables page scrolling with keyboard arrows
      e.preventDefault();
      switch (e.code) {
        case "ArrowUp":
          moveTiles("move_up");
          break;
        case "ArrowDown":
          moveTiles("move_down");
          break;
        case "ArrowLeft":
          moveTiles("move_left");
          break;
        case "ArrowRight":
          moveTiles("move_right");
          break;
        default:
          break;
      }
    },
    [moveTiles]
  );

  const handleSwipe = useCallback(
    (step) => {
      switch (step) {
        case KeyBoard.Down:
          moveTiles("move_down");
          break;
        case KeyBoard.Up:
          moveTiles("move_up");
          break;
        case KeyBoard.Left:
          moveTiles("move_left");
          break;
        case KeyBoard.Right:
          moveTiles("move_right");
          break;
        default:
          break;
      }
    },
    [moveTiles]
  );

  const renderGrid = () => {
    const cells = [];
    const totalCellsCount = 16;

    for (let index = 0; index < totalCellsCount; index += 1) {
      cells.push(<div className={styles.cell} key={index} />);
    }

    return cells;
  };

  const renderTiles = () => {
    return getTiles().map((tile) => <Tile key={`${tile.id}`} {...tile} />);
  };

  useEffect(() => {
    if (initialized.current === false) {
      startGame();
      initialized.current = true;
    }
  }, [startGame]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <MobileSwiper onSwipe={handleSwipe} className="h-full">
      <div className={styles.board}>
        {/* {status === "won" && <Splash heading="You won!" type="won" />} */}
        {/* {status === "lost" && <Splash heading="You lost!" />} */}
        <div className={styles.tiles}>{renderTiles()}</div>
        <div className={styles.grid}>{renderGrid()}</div>
      </div>
    </MobileSwiper>
  );
}
