import { move } from "./player";
import { updateMap } from "./map";
import { updateBackground, castRays } from "./renderer";
import { renderEnemies } from "./enemies";
import { renderSprites, clearSprites } from "./sprites";

window.onload = function () {
  //   mapWidth = map[0].length;
  //   mapHeight = map.length;
  //   addKeys();
  //   initScreen();
  //   initSprites();
  //   initEnemies();
  //   drawMap();
  //   gameCycle();
  //   renderCycle();
};

//----------------------------------------------------------

export const $ = function (id) {
  return document.getElementById(id);
};

export var timeouts = [];

//----------------------------------------------------------

var lastGameCycleTime = 0;
export var gameCycleDelay = 1000 / 30;

//----------------------------------------------------------

export const gameCycle = function () {
  var now = new Date().getTime();
  var timeDelta = now - lastGameCycleTime;

  move(timeDelta);

  var cycleDelay = gameCycleDelay;
  if (timeDelta > cycleDelay) {
    cycleDelay = Math.max(1, cycleDelay - (timeDelta - cycleDelay));
  }
  lastGameCycleTime = now;
  timeouts.push(setTimeout(gameCycle, cycleDelay));
};

//----------------------------------------------------------

export const renderCycle = function () {
  updateMap();
  clearSprites();
  castRays();
  renderSprites();
  renderEnemies();
  updateBackground();
  timeouts.push(setTimeout(renderCycle, gameCycleDelay));
};

//----------------------------------------------------------
