export default function Contact() {
  return (
    <article>
      <h1 className="text-xl font-bold">Get in touch</h1>
      <p>Have a question about the website?</p>
      <p>I'd love to hear from you! &#129303;</p>
      <p>
        Send me a message and I'll get back to you as soon as possible: <a href="mailto:levanduc094@gmail.com">levanduc094@gmail.com</a>
      </p>
    </article>
  );
}
