import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import play from "./icons/play.svg";

import Grid from "./Grid";
import { Icon } from "./Icon";
import IconButton from "./IconButton";
import Keys from "./Keys";

import { Step } from "./lib/search";

import { usePuzzle } from "./puzzle";
import { Theme, ThemeOption } from "./Theme";
import { THEME_COLORS } from "./tokens";
import MobileSwiper from "../../components/MobileSwiper";
import { KeyBoard } from "../../Constant";

const Content = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 12px;
  }
`;

const Head = styled.h2`
  text-align: center;
  color: white;
  font-family: var(--fontFamilyPrimary);
  margin: 0;
  font-weight: normal;
  font-size: 1.25rem;
`;

const Footer = styled.footer`
  margin: auto;
  padding: 64px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > * {
    margin: 8px auto;
  }
  span {
    color: white;
  }
  p {
    color: hsl(359, 0%, 90%);
  }
  a {
    text-decoration: none;
    color: hsl(359, 0%, 90%);

    span {
      display: inline-block;
    }

    & > span:after {
      content: "";
      display: block;
      width: 90%;
      margin: auto;
      height: 0px;
      border: 1px dashed white;
    }

    :hover > span:after {
      border: 1px solid white;
    }
  }
  div > a {
    display: flex;
    align-items: center;
    > * + * {
      margin-left: 8px;
    }
  }
  ${Icon} {
    fill: white;
  }
`;

const KeysContainer = styled.div`
  & > * + * {
    margin: 12px auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${Stack} {
    margin-top: 140px;
  }

  p {
    margin: 12px 0;
    font-family: var(--fontFamilyPrimary);
    color: white;
    font-size: 1rem;
  }

  h3 {
    padding: 0;
    font-family: var(--fontFamilySecondary);
    font-weight: normal;
    color: white;
    transition: opacity 0.5s ease, max-height 0.5s ease, margin 0.25s ease;
    max-height: 0px;
    overflow: hidden;
    margin: 0px;
    opacity: 0;
    &[data-show] {
      opacity: 1;
      max-height: 35px;
      margin: 16px;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${Content} {
      margin-left: 0px;
      width: 100%;
    }

    ${Stack} {
      margin-top: 32px;
    }

    ${KeysContainer} {
      display: flex;

      & > * {
        margin: auto 8px;
      }
    }
  }
`;

const AppWrapper = styled.div`
  min-height: 100vh;
  color: white;
  font-family: var(--fontFamilyPrimary);
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  margin: 10px;
  div {
    margin: 0 10px;
  }
`;

const Colors = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 4px;
  box-shadow: 0px 2px 1px 1px #aaa;
`;

function useHandler(value) {
  const [state, setState] = useState(value);

  const handler = useCallback(
    (value) => () => {
      setState(value);
    },
    []
  );

  return [state, handler];
}

export default function Game8puzzle() {
  const [state, dispatch] = usePuzzle();
  const [theme, handleTheme] = useHandler("purple");

  useEffect(() => {
    const keyListener = (event) => {
      event.preventDefault();
      switch (event.code) {
        case "Down":
        case "KeyS":
        case "ArrowDown":
          dispatch({ type: "MOVE", payload: Step.Down });
          break;
        case "Up":
        case "KeyW":
        case "ArrowUp":
          dispatch({ type: "MOVE", payload: Step.Up });
          break;
        case "Left":
        case "KeyA":
        case "ArrowLeft":
          dispatch({ type: "MOVE", payload: Step.Left });
          break;
        case "Right":
        case "KeyD":
        case "ArrowRight":
          dispatch({ type: "MOVE", payload: Step.Right });
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", keyListener);

    return () => {
      document.removeEventListener("keydown", keyListener);
    };
  }, [dispatch]);

  const start = () => {
    dispatch({ type: "START" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const random = () => {
    dispatch({ type: "RANDOM" });
  };

  const handleSwipe = useCallback((step) => {
    switch (step) {
      case KeyBoard.Down:
        dispatch({ type: "MOVE", payload: Step.Down });
        break;
      case KeyBoard.Up:
        dispatch({ type: "MOVE", payload: Step.Up });
        break;
      case KeyBoard.Left:
        dispatch({ type: "MOVE", payload: Step.Left });
        break;
      case KeyBoard.Right:
        dispatch({ type: "MOVE", payload: Step.Right });
        break;
      default:
        break;
    }
  }, []);

  return (
    <Theme data-theme={theme}>
      <MobileSwiper onSwipe={handleSwipe}>
        <AppWrapper>
          <ContentWrapper>
            <Content>
              <Grid data={state.gridData} squareShift={90} />
              {/* <div className="flex mt-[1rem] w-[300px] justify-around">
                <button onClick={start} className="bg-white text-red-700 font-semibold py-2 border border-red-700 rounded w-[7rem] z-[999]">
                  Play
                </button>
                <button onClick={random} className="bg-white text-red-700 font-semibold py-2 border border-red-700 rounded w-[7rem]">
                  Random
                </button>
              </div> */}
            </Content>
          </ContentWrapper>
        </AppWrapper>
      </MobileSwiper>
    </Theme>
  );
}
