// Pair
export const pairEq = ([a, b], [c, d]) => a == c && b == d;

export const pairSum = ([x1, y1], [x2, y2]) => [x1 + x2, y1 + y2];

export const pairDiff = ([x1, y1], [x2, y2]) => [x1 - x2, y1 - y2];

export const MAX_BOUND = 2;
const MIN_BOUND = 0;
const BOUNDS_RANGE = [MIN_BOUND, MAX_BOUND];

const isWithinRange = ([x, y], t) => x <= t && t <= y;

export const isValidPosition = ([x, y]) =>
  isWithinRange(BOUNDS_RANGE, x) && isWithinRange(BOUNDS_RANGE, y);
