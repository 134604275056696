import { GameContext } from "../../context/game-context";
import styles from "../../styles/score.module.css";
import React, { useContext } from "react";

export default function Score() {
  const { score } = useContext(GameContext);

  return (
    <div className={`${styles.score} text-white bg-[#7d664e] border-[#7d664e]`}>
      Score
      <div>{score}</div>
    </div>
  );
}
