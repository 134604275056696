import { gridFromList } from "./lib/grid";
import { isValidPosition, pairEq, pairSum } from "./lib/pair";
import { AStar, makeSearchState, Step } from "./lib/search";
import { useEffect, useReducer } from "react";
import puzzleData from "./puzzles.json";

// type PuzzleState = {
//   status: Status
//   gridData: GridData
//   solutionQueue: Array<Step>
//   initialList: Array<number>
//   currentList: Array<number>
// }

// type Action =
//   | { type: 'START' }
//   | { type: 'RESET' }
//   | { type: 'RANDOM' }
//   | { type: 'STOP' }
//   | { type: 'MOVE'; payload: Step }
//   | { type: 'RUN_SOLUTION' }

const Status = {
  Running: 0,
  Stopped: 1,
};

// type SquareData = {
//   digit: number
//   position: Pair
//   delta: Pair
// }

// export type GridData = {
//   zeroIndex: number
//   data: Array<SquareData>
// }

const zeroIndexFromList = (list) => {
  const index = list.findIndex((elem) => elem === 0);

  if (index >= 0) return index;
  throw Error(`Invalid list. The "zero" element is required. ${list}`);
};

const positionFromIndex = (index) => [
  Math.trunc(index % 3),
  Math.trunc(index / 3),
];

const makeGridDataFromList = (list) => ({
  zeroIndex: zeroIndexFromList(list),
  data: list.map((digit, index) => ({
    digit,
    position: positionFromIndex(index),
    delta: [0, 0],
  })),
});

const gridToList = (grid) => {
  const list = Array(grid.data.length).fill(0);
  grid.data.forEach(({ position: [x, y], digit }) => (list[y * 3 + x] = digit));

  return list;
};

const makeStateFromList = (list) => ({
  status: Status.Stopped,
  solutionQueue: [],
  gridData: makeGridDataFromList(list),
  initialList: list,
  currentList: list,
});

const nextGridDataFromList = (grid, list) => {
  const len = grid.data.length;

  const nextRandomGrid = Array(len);

  let digit;
  for (let index = 0; index < len; index++) {
    digit = list[index];
    nextRandomGrid[digit] = positionFromIndex(index);
  }

  const nextData = grid.data.map(({ digit }, index) => {
    const initialPosition = positionFromIndex(index);
    const nextPosition = nextRandomGrid[digit];
    const delta = deltaFromPairs(nextPosition, initialPosition);

    return {
      delta,
      digit,
      position: nextPosition,
    };
  });

  return {
    ...grid,
    data: nextData,
  };
};

const randomPuzzle = () => {
  const len = puzzleData.length;
  const random = Math.floor(Math.random() * len);

  return puzzleData[random];
};

// Relative to zero position
// row x col
const MOVES = {
  [Step.Down]: [0, -1],
  [Step.Up]: [0, 1],
  [Step.Left]: [1, 0],
  [Step.Right]: [-1, 0],
};

const pairToIndex = ([x, y]) => y * 3 + x;
const deltaFromPairs = ([a, b], [c, d]) => [a - c, b - d];
const updateGridData = (step, grid) => {
  const zeroSquare = grid.data[grid.zeroIndex];
  const nextZeroPosition = pairSum(zeroSquare.position, MOVES[step]);

  if (isValidPosition(nextZeroPosition)) {
    const nextIndex = grid.data.findIndex((el) =>
      pairEq(el.position, nextZeroPosition)
    );
    const zeroIndex = grid.zeroIndex;

    const nextSquare = grid.data[nextIndex];

    const nextData = [...grid.data];
    // Swapping
    nextData[zeroIndex] = {
      ...zeroSquare,
      delta: pairSum(
        zeroSquare.delta,
        deltaFromPairs(nextZeroPosition, zeroSquare.position)
      ),
      position: nextZeroPosition,
    };
    nextData[nextIndex] = {
      ...nextSquare,
      delta: pairSum(
        nextSquare.delta,
        deltaFromPairs(zeroSquare.position, nextSquare.position)
      ),
      position: zeroSquare.position,
    };

    return {
      ...grid,
      data: nextData,
    };
  }

  return grid;
};

const solvePuzzle = (state) => {
  const list = Array(9);
  for (const item of state.gridData.data) {
    list[pairToIndex(item.position)] = item.digit;
  }
  const ans = AStar(makeSearchState(gridFromList(list)));
  return ans;
};

const INITIAL_LIST = randomPuzzle();
const INITIAL_STATE = makeStateFromList(INITIAL_LIST);
const EMPTY_QUEUE = [];

const reducer = (state, action) => {
  let randomList;
  switch (action.type) {
    case "START":
      if (state.status === Status.Running) return state;
      return {
        ...state,
        solutionQueue: solvePuzzle(state).answer?.path ?? EMPTY_QUEUE,
        status: Status.Running,
      };
    case "STOP":
      return {
        ...state,
        solutionQueue: EMPTY_QUEUE,
        status: Status.Stopped,
      };
    case "RESET":
      return {
        ...state,
        solutionQueue: EMPTY_QUEUE,
        gridData: nextGridDataFromList(
          makeGridDataFromList(state.initialList),
          state.currentList
        ),
        status: Status.Stopped,
      };
    case "RANDOM":
      randomList = randomPuzzle();

      return {
        ...state,
        currentList: randomList,
        solutionQueue: EMPTY_QUEUE,
        gridData: nextGridDataFromList(state.gridData, randomList),
        status: Status.Stopped,
      };
    case "MOVE":
      if (state.status === Status.Running) return state;
      return {
        ...state,
        gridData: updateGridData(action.payload, state.gridData),
      };
    case "RUN_SOLUTION":
      return state.solutionQueue.length
        ? {
            ...state,
            solutionQueue: state.solutionQueue.slice(1),
            gridData: updateGridData(state.solutionQueue[0], state.gridData),
          }
        : {
            ...state,
            status: Status.Stopped,
          };
    default:
      return state;
  }
};

export const usePuzzle = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const isFinalState = gridToList(state.gridData).join("") === "123456780";

  useEffect(() => {
    if (state.status !== Status.Running) return;
    let timer;

    const tick = () => {
      dispatch({ type: "RUN_SOLUTION" });
      timer = setTimeout(tick, 250);
    };

    tick();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [state.status]);

  return [{ ...state, isFinalState }, dispatch];
};
