import React, { createContext, useCallback, useEffect, useReducer, PropsWithChildren } from "react";
import { isNil, throttle } from "lodash";
import { gameWinTileValue, mergeAnimationDuration, tileCountPerDimension } from "../constants";
import gameReducer, { initialState } from "../reducers/game-reducer";

export const GameContext = createContext({
  score: 0,
  status: "ongoing",
  moveTiles: (_) => {},
  getTiles: () => [],
  startGame: () => {},
});

export default function GameProvider({ children }) {
  const [gameState, dispatch] = useReducer(gameReducer, initialState);

  const getEmptyCells = () => {
    const results = [];

    for (let x = 0; x < tileCountPerDimension; x++) {
      for (let y = 0; y < tileCountPerDimension; y++) {
        if (isNil(gameState.board[y][x])) {
          results.push([x, y]);
        }
      }
    }
    return results;
  };

  const appendRandomTile = () => {
    const emptyCells = getEmptyCells();
    if (emptyCells.length > 0) {
      const cellIndex = Math.floor(Math.random() * emptyCells.length);
      const newTile = {
        position: emptyCells[cellIndex],
        value: 2,
      };
      dispatch({ type: "create_tile", tile: newTile });
    }
  };

  const getTiles = () => {
    return gameState.tilesByIds.map((tileId) => gameState.tiles[tileId]);
  };

  const moveTiles = useCallback(
    throttle((type) => dispatch({ type }), mergeAnimationDuration * 1.05, {
      trailing: false,
    }),
    [dispatch]
  );

  const startGame = () => {
    // available positions
    let positions = [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [1, 0],
      [1, 1],
      [1, 2],
      [1, 3],
      [2, 0],
      [2, 1],
      [2, 2],
      [2, 3],
      [3, 0],
      [3, 1],
      [3, 2],
      [3, 3],
    ];
    // set position1
    const position1 = positions[Math.floor(Math.random() * positions.length)];
    // set position2
    positions = positions.filter((item) => item !== position1);
    const position2 = positions[Math.floor(Math.random() * positions.length)];
    // start game
    dispatch({ type: "reset_game" });
    dispatch({ type: "create_tile", tile: { position: position1, value: 2 } });
    dispatch({ type: "create_tile", tile: { position: position2, value: 2 } });
  };

  const checkGameState = () => {
    const isWon = Object.values(gameState.tiles).filter((t) => t.value === gameWinTileValue).length > 0;

    if (isWon) {
      dispatch({ type: "update_status", status: "won" });
      return;
    }

    const { tiles, board } = gameState;

    const maxIndex = tileCountPerDimension - 1;
    for (let x = 0; x < maxIndex; x += 1) {
      for (let y = 0; y < maxIndex; y += 1) {
        if (isNil(gameState.board[x][y]) || isNil(gameState.board[x + 1][y]) || isNil(gameState.board[x][y + 1])) {
          return;
        }

        if (tiles[board[x][y]].value === tiles[board[x + 1][y]].value) {
          return;
        }

        if (tiles[board[x][y]].value === tiles[board[x][y + 1]].value) {
          return;
        }
      }
    }

    dispatch({ type: "update_status", status: "lost" });
  };

  useEffect(() => {
    if (gameState.hasChanged) {
      setTimeout(() => {
        dispatch({ type: "clean_up" });
        appendRandomTile();
      }, mergeAnimationDuration);
    }
  }, [gameState.hasChanged]);

  useEffect(() => {
    if (!gameState.hasChanged) {
      checkGameState();
    }
  }, [gameState.hasChanged]);

  return (
    <GameContext.Provider
      value={{
        score: gameState.score,
        status: gameState.status,
        getTiles,
        moveTiles,
        startGame,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
