import React, { useContext } from "react";
import Board from "./Board";
import Score from "./Score";
import styles from "../../styles/index.module.css";
import { ReplayIcon } from "../../components/ReplayIcon";
import { GameContext } from "../../context/game-context";

export default function Game2048() {
  const { startGame } = useContext(GameContext);

  return (
    <div className={`${styles.twenty48} h-full flex flex-col`}>
      <div className="flex items-center flex-col">
        <button aria-label="replay" className="ml-auto" onClick={startGame}>
          <ReplayIcon />
        </button>
        <div className="flex items-center w-full">
          <h1 style={{ flex: "1 1" }}>2048</h1>
          <Score />
        </div>
      </div>
      <Board />
    </div>
  );
}
